<template>
  <div>
    <Hero />
    <hr />
    <div class="notFound col-md-10 mb-4 mx-auto d-block">
    <p class="centered">Thank you for your submission.</p> 
    <router-link to="/">
      <button>Return to home page.</button>
    </router-link>
    </div>
    </div>


</template>

<script>
    import Hero from "../components/Hero";

    export default {
        components: {
            Hero,
    },
        name: 'sent'
    }
</script>